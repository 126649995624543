import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageAbout from './pages/about';
import PageCase from './pages/case';
import PageComingSoon from './pages/comingsoon';

// 活动页面
import PageActivityCIIE from './pages/activity/ciie';
import PageActivityTownProtect from './pages/activity/townProtect';
import PageActivityXueLeiFeng from './pages/activity/xueLeifeng';
import PageActivityTemp404 from './pages/activity/temp404';
import PageActivityTaoTemp from './pages/activity/taoTemp';
import PageActivitySHXinfang from './pages/activity/shxinfang';
import PageActivityPICS from './pages/activity/pics';
import PageActivityChuangwen2021 from './pages/activity/chuangwen2021';
import PageMatrixRender from './pages/activity/matrixRender';
import PageAnnounce from './pages/activity/announce';

// 临时寄宿页面
import PageHome from './pages/temp/home';
import PageCases from './pages/temp/cases';
import PageCasesDetail from './pages/temp/cases-detail';
import PageAboutUs from './pages/temp/about';
import PageContact from './pages/temp/contact';

// 朱家角相关页面
import PageZhujiajiaoHome from './pages/zhujiajiao/home';
import PageZhujiajiaoCard from './pages/zhujiajiao/card';
import PageZhujiajiaoStoreList from './pages/zhujiajiao/storeList';
import PageZhujiajiaoPassenger from './pages/zhujiajiao/ly_passenger';
import PageZhujiajiaoRecommend from './pages/zhujiajiao/ly_recommend';
import PageZhujiajiaoRecommends from './pages/zhujiajiao/ly_recommends';
import PageZhujiajiaoRecommendrotes from './pages/zhujiajiao/ly_recommendrots';
import PageZhujiajiaoParking from './pages/zhujiajiao/ly_parking';
import PageZhujiajiaoConte from './pages/zhujiajiao/conte';
// v2
import PageZhujiajiaoV2AdminLogin from './pages/zhujiajiao/v2/admin/login';
import PageZhujiajiaoV2AdminAnnounce from './pages/zhujiajiao/v2/admin/announce';
import PageZhujiajiaoV2AdminUser from './pages/zhujiajiao/v2/admin/adminUser';
import PageZhujiajiaoV2AdminCruise from './pages/zhujiajiao/v2/admin/cruise';
import PageZhujiajiaoV2AdminCruiseReserve from './pages/zhujiajiao/v2/admin/cruiseReserve';
import PageZhujiajiaoV2Announce from './pages/zhujiajiao/v2/announce';
import PageZhujiajiaoV2AnnounceDetail from './pages/zhujiajiao/v2/announce/detail';
import PageZhaoXiang from './pages/zhujiajiao/v2/zhaoxiang';
import PageChouJiang from './pages/zhujiajiao/v2/choujiang';
import PageChouJiangFuWei from './pages/zhujiajiao/v2/choujiang/fuwei';
import PageZhujiajiaoV2AdminZhaoxiangH5 from './pages/zhujiajiao/v2/admin/zhaoxiangh5';
import PageZhaoXiangDetail from './pages/zhujiajiao/v2/zhaoxiang/detail';
import PageZhujiajiaoV2SceneryVoiceIntro from './pages/zhujiajiao/v2/sceneryVoiceIntro';
import PageZhujiajiaoV2CruiseReserve from './pages/zhujiajiao/v2/cruiseReserve';
import PageZhujiajiaoV2CruiseReserveAction from './pages/zhujiajiao/v2/cruiseReserve/reserve';
import PageZhujiajiaoV2Matrix from './pages/zhujiajiao/v2/matrix';
import PageZhujiajiaoGuider from './pages/zhujiajiao/v2/guider';
import PageZhujiajiaoltphone from './pages/zhujiajiao/v2/ltphone'

//2025年三色练塘公众号升级
import PageSanHome from './pages/sanselt/home';
import PageSpindex from './pages/sanselt/spindex';


// 2020履职报告
import PageLZBGHome from './pages/lvzhibaogao2020/home';
import PageLZBGDetail from './pages/lvzhibaogao2020/detail';

// 白鹤大屏相关页面
import PageBaiheHome from './pages/baihe/home';
import PageBaiheVideo from './pages/baihe/video';
import PageBaiheShequ from './pages/baihe/shequ';
import PageBaiheDaibiao from './pages/baihe/daibiao';
import PageBaiheRedstory from './pages/baihe/redstory';
import PageBaiheContent from './pages/baihe/content';
import PageBaiheImages from './pages/baihe/images';
import PageBaiheImagesDetail from './pages/baihe/imagesDetail';

// import IMG_BG_0 from './pages/baihe/images/bg/0.jpg';
// import IMG_BG_HAPPINESS from './pages/baihe/images/bg/happiness.jpg';
// import IMG_BG_SHEQU from './pages/baihe/images/bg/shequ.jpg';
// import IMG_BG_CONTENT_1 from './pages/baihe/images/bg/content-1.jpg';
// import IMG_BG_PINPAI from './pages/baihe/images/bg/pinpai.jpg';
// import IMG_BG_PINPAI2 from './pages/baihe/images/bg/pinpai2.jpg';

const App = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageAbout} />
			<Route exact path="/about" component={PageAbout} />
			<Route exact path="/case" component={PageCase} />
			<Route exact path="/todo" component={PageComingSoon} />

			<Route exact path="/activity/ciie" component={PageActivityCIIE} />
			<Route exact path="/activity/town-protect" component={PageActivityTownProtect} />
			<Route exact path="/activity/20200305" component={PageActivityXueLeiFeng} />
			<Route exact path="/activity/404" component={PageActivityTemp404} />
			<Route exact path="/activity/taokoulin" component={PageActivityTaoTemp} />
			<Route exact path="/activity/shxf" component={PageActivitySHXinfang} />
			<Route exact path="/activity/pics" component={PageActivityPICS} />
			<Route exact path="/activity/chuangwen2021" component={PageActivityChuangwen2021} />

			<Route exact path="/matrix/:target" component={PageMatrixRender} />

      {/* 临时寄宿页面 */}
      <Route exact path="/home" component={PageHome} />
      <Route exact path="/cases" component={PageCases} />
      <Route exact path="/cases/:id" component={PageCasesDetail} />
      <Route exact path="/aboutus" component={PageAboutUs} />
      <Route exact path="/contact" component={PageContact} />

			{/* 朱家角相关页面 */}
      <Route exact path="/zhujiajiao" component={PageZhujiajiaoHome} />
      <Route exact path="/zhujiajiao/card" component={PageZhujiajiaoCard} />
      <Route exact path="/zhujiajiao/passenger" component={PageZhujiajiaoPassenger} />
      <Route exact path="/zhujiajiao/parking" component={PageZhujiajiaoParking} />
      <Route exact path="/zhujiajiao/conte" component={PageZhujiajiaoConte} />
      <Route exact path="/zhujiajiao/recommend" component={PageZhujiajiaoRecommend} />
      <Route exact path="/zhujiajiao/recommends" component={PageZhujiajiaoRecommends} />
      <Route exact path="/zhujiajiao/recommendrotes" component={PageZhujiajiaoRecommendrotes} />
      <Route exact path="/zhujiajiao/store-list" component={PageZhujiajiaoStoreList} />
      <Route exact path="/zhujiajiao/announce/:target" component={PageAnnounce} />
			{/*v2*/}
      <Route exact path="/zhujiajiao/v2/admin/login" component={PageZhujiajiaoV2AdminLogin} />
      <Route exact path="/zhujiajiao/v2/admin/announce" component={PageZhujiajiaoV2AdminAnnounce} />
      <Route exact path="/zhujiajiao/v2/admin/user" component={PageZhujiajiaoV2AdminUser} />
      <Route exact path="/zhujiajiao/v2/admin/cruise" component={PageZhujiajiaoV2AdminCruise} />
      <Route exact path="/zhujiajiao/v2/admin/reserve-order" component={PageZhujiajiaoV2AdminCruiseReserve} />
      <Route exact path="/zhujiajiao/v2/announce" component={PageZhujiajiaoV2Announce} />
      <Route exact path="/zhujiajiao/v2/announce/:id" component={PageZhujiajiaoV2AnnounceDetail} /> 
      {/* 下面这个路由是大唐做的赵巷的项目 */}
      <Route exact path="/zhujiajiao/v2/admin/zhaoxiangh5" component={PageZhujiajiaoV2AdminZhaoxiangH5} />
      <Route exact path="/h5page/v2/zhaoxiang" component={PageZhaoXiang} />
      <Route exact path="/h5page/v2/zhaoxiang/detail/:id" component={PageZhaoXiangDetail} />
      <Route exact path="/h5page/v2/choujiang" component={PageChouJiang} />
      <Route exact path="/h5page/v2/choujiang/fuwei" component={PageChouJiangFuWei} />
                	<Route exact path="/zhujiajiao/v2/scenery/voice/intro" component={PageZhujiajiaoV2SceneryVoiceIntro} />
			<Route exact path="/zhujiajiao/v2/cruise/reserve" component={PageZhujiajiaoV2CruiseReserve} />
			<Route exact path="/zhujiajiao/v2/cruise/reserve/action" component={PageZhujiajiaoV2CruiseReserveAction} />
			<Route exact path="/zhujiajiao/v2/matrix" component={PageZhujiajiaoV2Matrix} />
			<Route exact path="/zhujiajiao/guider" component={PageZhujiajiaoGuider} />
			<Route exact path="/zhujiajiao/v2/guider" component={PageZhujiajiaoGuider} />
	                <Route exact path="/zhujiajiao/v2/ltphone" component={PageZhujiajiaoltphone} />

			{/* 白鹤大屏相关页面 */}
			<Route exact path="/baihe" component={PageBaiheHome} />
			<Route exact path="/baihe/video" component={PageBaiheVideo} />
			<Route exact path="/baihe/red-story" component={PageBaiheRedstory} />
			<Route exact path="/baihe/shequ" component={PageBaiheShequ} />
			<Route exact path="/baihe/daibiao" component={PageBaiheDaibiao} />
			<Route exact path="/baihe/content/:id" component={PageBaiheContent} />
			<Route exact path="/baihe/pinpai" component={PageBaiheImages} />
			<Route exact path="/baihe/pinpai/:id" component={PageBaiheImagesDetail} />

		        {/* 三色练塘公众号升级 */}
			<Route exact path="/sanselt/spindex" component={PageSpindex} />
			<Route exact path="/sanselt/home/:id" component={PageSanHome} />


			{/* 2020履职报告相关页面 */}
			<Route exact path="/lzbg2020" component={PageLZBGHome} />
			{/*<Route exact path="/lzbg2020/detail/:id" components={PageLZBGDetail} />*/}
		</div>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// (() => {
// 	window.onload = function () {
// 		return;
// 		const div = document.createElement('div');
// 		div.style.backgroundImage = `url(${IMG_BG_PINPAI2})`;
// 		div.style.backgroundRepeat = 'no-repeat';
// 		div.style.position = 'fixed';
// 		div.style.backgroundSize = 'cover';
// 		div.style.left = 0;
// 		div.style.right = 0;
// 		div.style.top = 0;
// 		div.style.bottom = 0;
// 		div.style.zIndx = 99;
// 		div.style.opacity = 0.35;
// 		document.body.appendChild(div);
// 	}
// })();
