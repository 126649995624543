/**
 * Created by zds on 2025/02/24.
 */
import React, { useState, useEffect } from 'react';
import './index.less';
import { Button } from 'antd/lib/radio';

let H = ["陈云纪念馆", "爱国主义教育基地", "人大之家", "文旅地图", "茭白节", "村居", "练塘古镇","非遗文化"];

const PageModel = props => {

	return (
		<div className="sanse_spindex">
			{/* {
				H.map((item,index)=>{
					return <div key={index} className='div_btn' onClick={() => { props.history.push(`/sanselt/home/${index+1}`); }}>{item}</div>
				})
			} */}
			{/* {
				[
					{ icon: IMG_DAIBIAO_1, link: '/baihe/content/daibiao1' },
					{ icon: IMG_SHEQU_1, link: '/baihe/content/shequ1' },
					{ icon: IMG_SHEQU_2, link: '/baihe/content/shequ2' },
					{ icon: IMG_SHEQU_3, link: '/baihe/content/shequ3' },
					{ icon: IMG_SHEQU_4, link: '/baihe/content/shequ4' },
				].map((item, idx) => <div className="item" key={idx} onClick={() => {
					props.history.push(item.link);
					return;
				}}>
					<img src={item.icon} />
				</div>)
			} */}
			<Button onClick={()=>{
				window.location.href = 'ctrip://';
			}}>打开携程</Button>
			<Button onClick={() => {
				window.location.href = 'imeituan://';
			}}>打开美团</Button>
			<Button onClick={() => {
				window.location.href = 'alitrip://';
			}}>打开飞猪</Button>
		</div>
	);
};

export default PageModel;
