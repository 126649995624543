/**
 * Created by zds on 2025/02/24.
 */
import React, { useState, useEffect } from 'react';

import './index.less';

import IMG_Fen from './image/fenmian.jpg';
import IMG_New from './image/4.png';


let A = [{"name": [{ "name": "陈云纪念馆官网", "img": IMG_Fen, "url": "https://www.cyjng.net" },
	{ "name": "陈云纪念馆公众号", "img": IMG_Fen, "url": "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwODI5MzMxNA==#wechat_redirect" }]
		}];
let B = [{"name": [{ "name": "西乡烈士陵园", "img": IMG_Fen, "url": "" },
					{ "name": "小蒸农民暴动指挥所", "img": IMG_Fen, "url": "" },
					{ "name": "青浦现代农业园区", "img": IMG_Fen, "url": "https://www.qpxdnyyq.com" }]},
		{"name": [{ "name": "阿特麦文化创意产业园", "img": IMG_Fen, "url": "https://mp.weixin.qq.com/s?__biz=MzAxOTY5MTY0MA==&mid=2652786382&idx=5&sn=bf70ad161dd846ceb0068f43a238adcd&chksm=8029cfd9b75e46cf81da7e30b1522d6833c13d10ce2e4ae6dc86fafe5078f2700a403a49612d&scene=27" }]
		}];
let C = [{ "name": [{ "name": "推文信息集锦", "img": IMG_Fen, "url": "http://mp.weixin.qq.com/mp/homepage?__biz=MzI5MzU0MjExNg==&hid=2&sn=88bb036f889758a4e79569cf03db346f&scene=18#wechat_redirect" }] }];

let D = [{ "name": [{ "name": "地图电子高清版本", "img": IMG_Fen, "url": "https://www.yeekin.cn/zhujiajiao/v2/ltphone" }] }];
let E = [{ "name": [{ "name": "历届茭白节", "img": IMG_Fen, "url": "https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MzU0MjExNg==&hid=3&sn=d88d53d912049acc816074daf9404216&scene=18" }] }];

let F = [{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }]},
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		{ "name": [{ "name": "村居1", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }, { "name": "村居2", "img": IMG_Fen, "url": "" }] },
		];


let G = [{"name": [{ "name": "古镇简介", "img": IMG_Fen, "url": "http://www.tripgm.com/oa/4/poi/396" },
			{ "name": "特色场馆", "img": IMG_Fen, "url": "http://www.tripgm.com/oa/4/poi/397" },
			{ "name": "练塘烟火气", "img": IMG_Fen, "url": "https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MzU0MjExNg==&hid=3&sn=d88d53d912049acc816074daf9404216&scene=18" }]
		}];
let H = [{"name": [{ "name": "吴歌(青浦田山歌)", "img": IMG_Fen, "url": "" },
			{ "name": "土布染织技艺", "img": IMG_Fen, "url": "" },
			{ "name": "练塘茭白叶编结制作技艺", "img": IMG_Fen, "url": "" }]},
		{"name": [{ "name": "匍经", "img": IMG_Fen, "url": "" },
			{ "name": "练塘糕团制作技艺", "img": IMG_Fen, "url": "" },
			{ "name": "桉叶糖制作技艺", "img": IMG_Fen, "url": "" }]},
		{ "name": [{ "name": "庆号习俗", "img": IMG_Fen, "url": "" }] 
		}];

const PageModel = props => {
	const [data, setData] = useState([]);
	const getData = async () => {
		const { id } = props.match.params;
		if (id == 1) {
			setData(A);
		} else if (id == 2) {
			setData(B);
		} else if (id == 3) {
			setData(C);
		} else if (id == 4) {
			setData(D);
		} else if (id == 5) {
			setData(E);
		} else if (id == 6) {
			setData(F);
		} else if (id == 7) {
			setData(G);
		} else if (id == 8) {
			setData(H);
		}
	};

	useEffect(() => {
		getData();
		return () => false;
	}, [props.match.params]);


	return (
		<div className="sanse_home">
			{data.map((item, index) => {
				return <div key={index} className='book'>
					<div className="fm">
						{
							item.name.map((items, ids) => {
								return <img key={ids} className="fmimg" src={items.img} onClick={()=>{
									window.location.href = items.url;
								}}/>
							})
						}
					</div>
					<img className="jia" src={IMG_New} key={1} />
					<div className="fmtext">
						{
							item.name.map((items, ids) => {
								return <div key={ids} className='shutitle'>《{items.name}》</div>
							})
						}
					</div>
				</div>
			})}
		</div>
	);
};

export default PageModel;
